import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttonwwd from "../../assets/jss/material-kit-react/buttonwwd.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";


function ButtonWWD() {
    return (
      <div>
        {/* <Link className="buttoncustom" to="/location">MORE INFORMATION</Link> */}
        <AniLink className="buttonwwd" paintDrip to="/what-we-do"  hex="#0385BB">
</AniLink>
      </div>
    )
  }

  export default withStyles(buttonwwd)(ButtonWWD);