import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttoncolliery from "../../assets/jss/material-kit-react/buttoncolliery.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";


function ButtonColliery() {
    return (
      <div>
        {/* <Link className="buttoncustom" to="/location">MORE INFORMATION</Link> */}
        <AniLink className="buttoncolliery" paintDrip to="/developments"  hex="#0385BB">
</AniLink>
      </div>
    )
  }

  export default withStyles(buttoncolliery)(ButtonColliery);